import styles from '../App.module.css'

export default function SearchProduct({ data }) {
  const datetimeserial = new Date().toISOString().replaceAll('-','').replaceAll(':','').replaceAll('.','').replaceAll('T','.').substring(0,11) + "00";
  const noimage = process.env.PUBLIC_URL + '/noimage.svg';
  const image = data.picturename ? data.picturename+'?'+datetimeserial : noimage;
  const productdefaulthexcolor = "#00395B";

  return (
    <>
      <div className={styles.grid}>
        <div className={styles.card} style={{ borderColor: productdefaulthexcolor }}>
          <div className={styles.cardimg}>
            <a href="#img1">
              <img src={image} alt="No image" onError={(e) => {e.target.src = noimage}}></img>
            </a>
            <a href="#" className={styles.lightbox} id="img1">
              <div>
                <img src={image} alt="No image" onError={(e) => {e.target.src = noimage}}></img>
              </div>
            </a>
          </div>
          <div className={styles.cardcontent}>
            <h3 style={{ display: data.producttitle ? "block" : "none" }}>{data.producttitle}</h3>
            <p style={{ display: data.sku ? "block" : "none" }}><b>Part Number:</b> {data.sku}</p>
            <p style={{ display: data.size ? "block" : "none" }}><b>Size:</b> {data.size}</p>
            <p style={{ display: data.material ? "block" : "none" }}><b>Material:</b> {data.material}</p>
            <p style={{ display: data.color ? "block" : "none" }}><b>Color:</b> { data.color ? data.color : ""}</p>
            <p style={{ display: data.unitofmeasure ? "block" : "none" }}><b>Unit of Measure:</b> {data.unitofmeasure}</p>
            <div className={styles.pricebox} style={{ display: data.price ? "block" : "none", backgroundColor: data.hexcolor ? data.hexcolor : productdefaulthexcolor }}>
              <div className={styles.price} style={{ display: data.price ? "block" : "none" }}>
                {data.price}
              </div>
              <div className={styles.price25plus} style={{ display: data.price25plus ? "block" : "none" }}>
                {data.price25plus} for 25+
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}