import React from 'react';
import styles from '../App.module.css'

export default function Terms() {
  return (
    <>
      <div className={styles.terms}>
        <h4>TERMS AND CONDITIONS</h4>
        <p>The following Terms and Conditions ("Terms and Conditions") shall apply to all information provided on SafetyPricing.com (“SafetyPricing.com”, “us” or “we”) to a Customer of SafetyPricing.com (“Customer” or "you"), and every agreement or other undertaking by SafetyPricing.com is expressly conditioned on your assent to these Terms and Conditions.</p>
        <h4>PRODUCT INFORMATION</h4>
        <p>The sole purpose of SafetyPricing.com is to provide pricing information on certain products.  All prices are subject to change without notice and in no circumstances shall any contract arise from the placement of an order (placed through the website, by phone, or otherwise) unless and until written confirmation is provided by the product seller.</p>
        <p>Unless otherwise stated, all sizes of products are provided in inches. Measurements given for products on the website may vary slightly from actual.</p>
        <p>Photos of products on the website may not be of the exact items that you intend to purchase. Physical properties of products such as size, shape, color, finish, the number and placement of holes, and cornering are examples of physical properties that may have changed or may not be the same as what is shown in the photo.</p>
        <h4>NO CONSEQUENTIAL DAMAGES/CUSTOMER INDEMNIFICATION</h4>
        <p>In no event shall we or the product seller(s) be liable for any indirect, special, or consequential damages, whether foreseeable or unforeseeable and whether based upon lost goodwill, lost resale profits, loss of use of money, work stoppage, impairment of other assets, or otherwise and whether arising out of breach of warranty, breach of contract, strict liability in tort, negligence, misrepresentation, or otherwise, to the extent permitted by law.</p>
        <p>Customer (as "Indemnifying Party") shall indemnify, defend and hold harmless SafetyPricing.com and its Representatives/officers, directors, employees, agents, affiliates, successors and permitted assigns (collectively, "Indemnified Party") against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorneys' fees, fees and the costs of enforcing any right to indemnification under these Terms and Condition, including the cost of pursuing any insurance providers, (collectively, "Losses"), relating to any claim of a third party alleging:</p>
        <p>A. a material breach or non-fulfillment of any representation, warranty or covenant under these Terms and Conditions (including non-infringement) by Indemnifying Party or Indemnifying Party's Personnel</p>
        <p>B. any negligent or more culpable act or omission of Indemnifying Party or its Personnel (including any recklessness or willful misconduct) in connection with the performance of its obligations under these Terms and Conditions;</p>
        <p>C. any bodily injury, death of any person or damage to real or tangible personal property caused by the willful negligent acts or omissions of Indemnifying Party or its agents or representatives; or</p>
        <p>D. any failure by Indemnifying Party or its personnel to materially comply with any applicable laws.</p>
        <h4>GENERAL</h4>
        <p>The invalidity or unenforceability (in whole or in part) of any provision, term or condition hereof, will not affect in any way the validity and enforceability of the remainder of such provision, term or condition, or any other provision, term or condition. These Terms and Conditions may be modified by SafetyPricing.com at any time upon written notification to Customer. No other changes or modification will be effective unless agreed to in writing by SafetyPricing.com.</p>
        <p>All remedies available to SafetyPricing.com under these Terms and Conditions will be cumulative, and not alternative or exclusive, and will be in addition to all other rights and remedies provided by applicable law. The exercise or failure to exercise any remedy by SafetyPricing.com will not preclude the exercise of the same or other remedies under these Terms and Conditions. SafetyPricing.com will not be deemed to have waived any provision of these Terms and Conditions or any breach by Customer of any provision hereof, unless specifically set forth in writing and executed by an authorized representative of SafetyPricing.com. No such waiver by SafetyPricing.com will constitute a waiver of such provision or breach on any other occasion. SafetyPricing.com shall not be responsible for any failure to fulfill its obligations hereunder due to causes beyond its reasonable control, including without limitation, acts or omissions of government or military authority, terrorist actions, acts of God, shortages of materials, transportation delays, fires, floods, labor disturbances, riots or wars. These Terms and Conditions, and the transactions contemplated hereby, will be governed by, construed and interpreted in accordance with the substantive laws of the Commonwealth of Virginia without regard to its conflict of laws rules. These Terms and Conditions, together with any quotation, order acceptance, or invoice subject to these Terms and Conditions, constitute the entire agreement and understanding of SafetyPricing.com and Customer. SafetyPricing.com assumes no liability except as expressly provided in these Terms and Conditions.</p>
      </div>
    </>
  )
}