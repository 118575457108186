import styles from '../App.module.css'
import { Link } from 'react-router-dom';

export default function Navbar() {
  return (
    <>
      <h1 className={styles.title}>
        <Link to="/">SafetyPricing.com</Link>
      </h1>
    </>
  )
}