import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SearchTextProvider } from "./context/searchtext";
import Layout from "./pages/layout";
import Main from './pages/main'
import Terms from './pages/terms.js'
import Status from './pages/status.js'
import NotFound from './pages/notfound.js'

function App() {
  return (
    <SearchTextProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/status" element={<Status />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </SearchTextProvider>
  );
}

export default App;
