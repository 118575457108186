import React from 'react';
import styles from '../App.module.css'

export default function Status(context) {
  return (
    <>
      <div className={styles.grid}>
        <div className={styles.card}>
          <div className={styles.cardcontent}>
            <h5>
              SafetyPricing.com
            </h5>
            <h5>
              20240331.2
            </h5>
          </div>
        </div>
      </div>
    </>
  )
}