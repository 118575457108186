import styles from '../App.module.css'
import { useSearchTextContext } from "../context/searchtext";
import { useState } from "react";
import SearchResults from "./searchresults"

export default function SearchForm() {
  const [searchtext, setSearchText] = useSearchTextContext();
  const [inputtext, setInputtext] = useState("");

  const submitSearch = async (e) => {
    e.preventDefault();
    setInputtext(searchtext);
  };
  
  const handleChange = e => {
    e.preventDefault();
    setSearchText(e.target.value);
    setInputtext("");
  };

  const handleReset = e => {
    e.preventDefault();
    setSearchText("");
    setInputtext("");
    document.getElementById("searchform").reset();
    document.getElementById("searchinput").focus();
  };

  return (
    <>
      <form id='searchform' onSubmit={e => submitSearch(e)}>
        <div className={styles.searchcontainer}>
          <div className={styles.searchbar}>
              <input id='searchinput' type="search" title="Enter a part number..." placeholder="Enter a part number..." onInput={e => handleChange(e)} autoFocus="autoFocus"/>
              <button type="submit" title="Search" className={styles.searchbutton}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-5 -7 35 35" strokeWidth={3.0} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
              </button>
              <button type="reset" title="Reset" className={styles.resetbutton} onClick={e => handleReset(e)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-5 -7 35 35" strokeWidth={3.0} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
          </div>
        </div>
        <div>
            <SearchResults inputtext={inputtext} />
        </div>
      </form>
    </>
  )
}