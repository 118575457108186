import styles from '../App.module.css'
import useSWR from "swr";
import Loading from "./loading"
import Error from "./error"
import SearchProduct from "./searchproduct"

export default function SearchResults({ inputtext }) {
  const defaulthexcolor = "#00395B";
  const fetcher = (url) => fetch(
    url, 
    {
      headers: 
      {
        "Content-Type": "application/json", 
        "Sp-App-Version": "20240331.2", 
        "Sp-App-Subscription-Key": "a999888777666555444333222111000xyz"
      }
    }
  ).then((res) => res.json());

  const { data, error, loading } = useSWR(inputtext ? "/api/product/" + inputtext : null, fetcher, { revalidateOnFocus: false }, {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      console.log(retryCount+'>'+error.status+'|'+error);

      // Never retry on 404.
      if (error.status === 404) {
        return;
      };

      // Only retry up to 3 times.
      if (retryCount >= 3) {
        return;
      }
  
      // Retry after 3 seconds.
      setTimeout(() => revalidate({ retryCount }), 3000)
    }
  });

  if (!inputtext) return <p />
  if (loading) return <Loading />
  if (error) return <Error />
  if (!data) return <Loading />

  console.log("input:"+inputtext);

  if (data.details.recordsfound === 0) {
    return (
      <>
        <div className={styles.grid}>
          <h3 className={styles.norecords} style={{ color: defaulthexcolor}}><p>{data.details.searchinfo}</p></h3>
        </div>
      </>
    )
  }
  else {
    if (data.details.recordsfound === 1) {
      return (
        <>
          <SearchProduct data={data}/>
        </>
      )
    }
    else {
      return (
        <>
          <div className={styles.grid}>
            <h3 className={styles.norecords} style={{ color: defaulthexcolor}}><p>{data.details.searchinfo}</p></h3>
          </div>
        </>
      )
    }
  }
}