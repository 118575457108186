import styles from '../App.module.css'

export default function Error() {
  return (
    <>
      <div className={styles.progresscontainer}>
        <div className={styles.progressbar}>
          <div className={styles.progressbarerrorvalue}></div>
        </div>
      </div>
    </>
  )
}