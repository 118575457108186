import styles from '../App.module.css'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <footer className={styles.footer}>
        <ul>
          <li><Link to="/">SafetyPricing.com</Link></li>
          <li><Link to="/terms">Terms and Conditions</Link></li>
        </ul>
        <h5>© {(new Date().getFullYear())} SafetyPricing.com. All rights reserved.</h5>
      </footer>
    </>
  )
}