import { createContext, useContext, useState } from "react";

const Context = createContext();

export function SearchTextProvider({ children }) {
  const [searchtext, setSearchText] = useState("");
  return (
    <Context.Provider value={[searchtext, setSearchText]}>{children}</Context.Provider>
  );
}

export function useSearchTextContext() {
  return useContext(Context);
}