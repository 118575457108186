import { Outlet } from "react-router-dom";

import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import styles from '../App.module.css'

function Layout() {

  return (
    <div className={styles.container}>
      <Navbar />

      {/* An <Outlet> renders whatever child route is currently active in App.js */}
      <div className={styles.main}>
        <Outlet />
      </div>

      <Footer />
    </div>
  );
}

export default Layout;