import styles from '../App.module.css'
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <>
      <div className={styles.grid}>
        <div className={styles.card} style={{ borderColor: "#00395B", backgroundColor: "#00395B" }}>
          <div className={styles.cardcontent} style={{ color: "#ffffff", textAlign: "center" }}>
            <p><br/></p>
            <p><b><h2>Oops! Looks like you made a wrong turn. </h2></b></p>
            <p><br/></p>
            <p><b><h2>You should change course for the day and go <u><Link to="/">home</Link></u> &#10140;</h2></b></p>
            <p><br/></p>
          </div>
        </div>
      </div>
    </>
  )
}